import { ipStringToLong } from '../helpers/orderReportingHelper';

class FailedOrderSearchModel {
    constructor (fromDate, toDate, errorReason = '', errorType = '', orderID = '', targetProducts = '', ipUnformated = '') {
        this.fromDate = fromDate ? new Date(fromDate) : null;
        this.toDate = toDate ? new Date(toDate) : null;
        this.errorReason = errorReason || null;
        this.errorType = errorType || null;
        this.orderID = orderID || null;
        this.targetProducts = targetProducts || null;
        this.ipUnformated = ipUnformated || null;
        this.ipFormatted = ipUnformated ? ipStringToLong(ipUnformated) : null;
    }

    toString() {
        return JSON.stringify({
            yearDate: this.getYearMonthValues(this.fromDate, this.toDate).join(','),
            fromDate: this.fromDate,
            toDate: this.toDate,
            errorReason: this.errorReason,
            errorType: this.errorType,
            orderID: this.orderID,
            targetProducts: this.targetProducts,
            ip: this.ipFormatted
        });
    }

    getYearMonthValues(startDate, endDate) {
        if (!startDate) {
            startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 1, 1);
        }
    
        if (!endDate) {
            endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
        }
    
        const result = [];
        let currentDate = new Date(startDate);
    
        while (currentDate <= endDate) {
            const year = currentDate.getFullYear();
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            result.push(`${year}${month}`);
            currentDate.setMonth(currentDate.getMonth() + 1);
        }
    
        return result;
    }

    toObject() {
        return {
            yearDate: this.getYearMonthValues(this.fromDate, this.toDate).join(','),
            fromDate: this.fromDate ? Number(this.fromDate) : null,
            toDate: this.toDate ? Number(this.toDate) : null,
            errorReason: this.errorReason,
            errorType: this.errorType,
            orderID: this.orderID,
            targetProducts: this.targetProducts,
            ip: this.ipFormatted
        };
    }
}

export default FailedOrderSearchModel;
