const SEARCH_RESULT_PAGE_COUNT = 100;

function ipStringToLong(ipString) {
    var octets = ipString.split('.');
    if (octets.length !== 4) {
        throw new Error("Invalid format -- expecting a.b.c.d");
    }
    var ip = 0;
    for (var i = 0; i < octets.length; ++i) {
        var octet = parseInt(octets[i], 10);
        if (Number.isNaN(octet) || octet < 0 || octet > 255) {
            throw new Error("Each octet must be between 0 and 255");
        }
        ip += octet << ((octets.length - 1 - i) * 8); // Corrected bitwise operation
    }
    return ip >>> 0; // Unsigned right shift to ensure positive value
}

function longToIpString(ipLong) {
    if (ipLong < 0 || ipLong > 4294967295) {
        throw new Error("IP address out of range");
    }

    return [
        (ipLong >>> 24) & 0xFF,
        (ipLong >>> 16) & 0xFF,
        (ipLong >>> 8) & 0xFF,
        ipLong & 0xFF
    ].join('.');
}

const failedOrderErrors = {
    'NO INVENTORY': {
        ID: 'NO_INVENTORY',
        color: 'forestgreen',
        description: 'Aptos inventory for this product was 0 when placing the order, but SFCC had no inventory.'
    },
    'FORTER': {
        ID: 'FORTER',
        color: 'royalblue',
        description: 'Forter did a fraud check and blocked the order creation.'
    },
    'CC DECLINED': {
        ID: 'CC_DECLINED',
        color: 'turquoise',
        description: 'Customers card has been declined for any card related reason.'
    },
    'MISSING CUSTOMER DATA': {
        ID: 'MISSING_CUSTOMER_DATA',
        color: 'midnightblue',
        description: 'Customers order is missing some of the mandatory data like: email, shipping address details, billing address details.'
    },
    'PAYMENT ISSUE': {
        ID: 'PAYMENT_ISSUE',
        color: 'tomato',
        description: 'Payment related issue to the customers payment method.'
    },
    'PRE AUTH BYPASS': {
        ID: 'PRE_AUTH_BYPASS',
        color: 'lime',
        description: 'Somebody directy trying to place the order without going trough the proper SFCC checkout. Most probably with a script.'
    },
    'VALIDATION ERROR': {
        ID: 'VALIDATION_ERROR',
        color: 'gold',
        description: 'A pretty general order validation issue. It can be: product validation related (invalid product data, going over max allowed limit for this item per order, trying to buy a released item that is currently not available, hyperelease related issue), validates if coupons are valid (if there are any), validates if order shipments are valid with proper addresses.'
    },
    'HYPE RELEASE LIMIT REACHED': {
        ID: 'HYPE_RELEASE_LIMIT_REACHED',
        color: 'teal',
        description: 'Exceeding the max bulk buyer limit on regular products using them or on the hype products.'
    },
    'MAX ORDER LIMIT REACHED': {
        ID: 'MAX_ORDER_LIMIT_REACHED',
        color: 'cornflowerblue',
        description: 'Trying to purchase more variants then it is allowed in the current cart.'
    },
    'QUEUEIT FAIL': {
        ID: 'QUEUEIT_FAIL',
        color: 'slateblue',
        description: 'Queue token validation fail.'
    },
    'INVALID PHONE': {
        ID: 'INVALID_PHONE',
        color: 'red',
        description: 'Phone related issue.'
    },
    'PLACE ORDER ERROR': {
        ID: 'PLACE_ORDER_ERROR',
        color: 'darksalmon',
        description: 'Error placing the order in SFCC.'
    },
    'INVALID ADDRESS': {
        ID: 'INVALID_ADDRESS',
        color: 'seagreen',
        description: 'Apple pay related invalid address issue.'
    },
    'MISSING STORE': {
        ID: 'MISSING_STORE',
        color: 'gainsboro',
        description: 'Ship to store or BOPIS order without a valid store number saved on the basket/order.'
    },
    'OCAPI ERROR': {
        ID: 'OCAPI_ERROR',
        color: 'goldenrod',
        description: 'Issue reseting the SFCC inventory to 0 in the case customer tries to purchase something that has inventory on SFCC but have no inventory on APTOS.'
    },
    'MISSING PAYMENT': {
        ID: 'MISSING_PAYMENT',
        color: 'moccasin',
        description: 'Missing payment information on the basket/order.'
    },
    'CYBERSOURCE ERROR': {
        ID: 'CYBERSOURCE_ERROR',
        color: 'powderblue',
        description: 'Cybersource generated error on payment capture.'
    },
    'INVALID EMAIL': {
        ID: 'INVALID_EMAIL',
        color: 'orange',
        description: 'Invalid email on order placement.'
    },
    'UNKNOWN': {
        ID: 'UNKNOWN',
        color: 'sienna',
        description: 'Unkown error.'
    }
};

const failedOrderErrorReasons = [
    'ORDER',
    'CART'
];

export {
    ipStringToLong,
    longToIpString,
    failedOrderErrors,
    failedOrderErrorReasons,
    SEARCH_RESULT_PAGE_COUNT
}

