import { createSlice } from '@reduxjs/toolkit';
import {
    getOrderReportingFiles,
    searchFailedReportData
} from '../services/awsService';
import { SEARCH_RESULT_PAGE_COUNT } from '../../helpers/orderReportingHelper';

const SLICE_NAME = 'orderReporting';

export const storeIconsSlice = createSlice({
    name: SLICE_NAME,
    initialState: {
        files: [],
        searchResults: [],
        isLoadingFiles: null,
        isLoadingSearchData: null,
        error: null
    },
    reducers: {
        setData: (state, action) => {
            if (action.payload) {
                state.files = action.payload.sort((a, b) => {
                    return (Number(b.year * 1000) + Number(b.month * 1000)) - (Number(a.year * 1000) + Number(a.month * 1000));
                });
            };
        },
        setOrderData: (state, action) => {
            if (typeof action.payload === 'object') {
                let chunks = [];
                
                for (let i = 0; i < action.payload.length; i += SEARCH_RESULT_PAGE_COUNT) {
                    chunks.push(action.payload.slice(i, i + SEARCH_RESULT_PAGE_COUNT));
                }

                state.searchResults = chunks;
            }
        },
        setLoading: (state, action) => {
            state[action.payload.type] = action.payload.value;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const fetchOrderReportingFilesAsync = () => async (dispatch, getState) => {
    const currentState = getState().orderReporting;

    if (currentState.isLoadingFiles) {
        return;
    }

    dispatch(setLoading({
        type: 'isLoadingFiles',
        value: true
    }));

    try {
        const orderReportingFilesResult = await getOrderReportingFiles();

        if (orderReportingFilesResult.success) {
            dispatch(setData(orderReportingFilesResult.data));
        }
    } catch (error) {
        dispatch(setError(error.message));
    }

    dispatch(setLoading({
        type: 'isLoadingFiles',
        value: false
    }));
};

export const fetchOrderReportingDBDataAsync = (searchModel) => async (dispatch, getState) => {
    const currentState = getState().orderReporting;

    if (currentState.isLoadingSearchData) {
        return;
    }

    dispatch(setLoading({
        type: 'isLoadingSearchData',
        value: true
    }));

    try {
        const orderReportingResponse = await searchFailedReportData(searchModel);

        if (orderReportingResponse.data) {
            dispatch(setOrderData(orderReportingResponse.data));
        }
    } catch (error) {
        dispatch(setError(error.message));
    }

    dispatch(setLoading({
        type: 'isLoadingSearchData',
        value: false
    }));
};

export const {
    setData,
    setOrderData,
    addLinks,
    removeLink,
    setLoading,
    setError
} = storeIconsSlice.actions;
export default storeIconsSlice.reducer;
