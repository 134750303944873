import RouteModel from '../models/RouteModel';
import Welcome from '../pages/Welcome';
import Map from '../pages/Map';
import AdminControlPanel from '../pages/admin/AdminControlPanel';
import StoreMessages from '../pages/admin/StoreMessages';
import IPStoreAccess from '../pages/admin/IPStoreAccess';
import AdminLinkControl from '../pages/admin/AdminLinkControl';
import OrderErrorReporting from '../pages/admin/OrderErrorReporting';
import AdminFailedOrderSearch from '../pages/admin/AdminFailedOrderSearch';

import Login from '../pages/Login';
import NoStoreAccount from '../pages/NoStoreAccount';
import Error from '../pages/Error';
import { PROTECTION_LEVEL } from '../components/auth/ProtectedRoute';

const ROUTING = {
    WELCOME: new RouteModel(
        '/',
        <Welcome/>,
        { message: 'Loading!' },
        'Welcome'
    ),
    MAP: new RouteModel(
        '/map',
        <Map/>,
        { message: 'Loading!' },
        'Site Mapping',
        PROTECTION_LEVEL.STORE
    ),
    ADMIN: new RouteModel(
        '/admin/control-center',
        <AdminControlPanel/>,
        { message: 'Loading!' },
        'Control Center',
        PROTECTION_LEVEL.STORE
    ),
    ADMIN_MESSAGES: new RouteModel(
        '/admin/messages',
        <StoreMessages/>,
        { message: 'Loading!' },
        'Store Messages',
        PROTECTION_LEVEL.ADMIN
    ),
    ADMIN_IP_ACCESS: new RouteModel(
        '/admin/ip-access',
        <IPStoreAccess/>,
        { message: 'Loading!' },
        'IP Store Access',
        PROTECTION_LEVEL.ADMIN
    ),
    ADMIN_ICONS: new RouteModel(
        '/admin/icons',
        <AdminLinkControl/>,
        { message: 'Loading!' },
        'Store Icon Generation',
        PROTECTION_LEVEL.ADMIN
    ),
    ADMIN_ORDER_ERROR_REPORTING: new RouteModel(
        '/admin/error-reporting',
        <OrderErrorReporting/>,
        { message: 'Loading!' },
        'Order/Cart Error Reporting',
        PROTECTION_LEVEL.ADMIN
    ),
    ADMIN_ORDER_ERROR_SEARCH: new RouteModel(
        '/admin/error-reporting-search',
        <AdminFailedOrderSearch/>,
        { message: 'Loading!' },
        'Order/Cart Error Search',
        PROTECTION_LEVEL.ADMIN
    ),
    LOGIN: new RouteModel(
        '/admin/login',
        <Login/>,
        { message: 'Loading!'},
        'Login'
    ),
    STORE_NO_ACCESS: new RouteModel(
        '/not-allowed',
        <NoStoreAccount/>,
        { message: 'Loading!' },
        'No Access'
    ),
    GENERAL_ERROR: new RouteModel(
        '*',
        <Error/>,
        { message: 'Loading!' },
        'Error'
    )
};

export { ROUTING };
