import React from 'react';
import { connect } from 'react-redux';
import AdminWrapper from './AdminControlPanel';
import Pagination from 'react-bootstrap/Pagination';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import './AdminFailedOrderSearch.css';
import {
    fetchOrderReportingDBDataAsync
} from '../../stateManagment/slices/orderReportingSlice';
import { failedOrderErrors, failedOrderErrorReasons, SEARCH_RESULT_PAGE_COUNT } from '../../helpers/orderReportingHelper';
import SearchModel from '../../models/FailedOrderSearchModel';

class AdminFailedOrderSearch extends React.Component {
    YEAR_DATE_PATTERN = '^(19|20)\\d{2}(0[1-9]|1[0-2])$';
    IP_PATTERN = '^$|^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$';

    constructor(props) {
        super(props);

        this.state = {
            fromDate: '',
            toDate: '',
            errorReason: '',
            errorType: '',
            orderID: '',
            targetProducts: '',
            ip: '',
            errorMessage: '',
            activePagination: 1,
            initiatedSearch: false,
            showSuccessMessage: false,
            validated: false
        }
    }
    
    handleModalClose = () => {
        this.setState({ showSuccessMessage: false });
    }

    handleModalShow = (searchData) => {
        navigator.clipboard.writeText(JSON.stringify(searchData, null, 4))
        this.setState({ showSuccessMessage: true });
    }

    buildPagination() {
        return <Pagination className="flex-wrap">
            {
                this.props.orderReporting?.searchResults.map((chunk, index) => {
                    let pagination = index + 1;
                    return <Pagination.Item
                        className="pagination-element"
                        onClick={() => {this.setState({activePagination: pagination})}}
                        key={pagination}
                        active={pagination === this.state.activePagination}>
                        {pagination}
                    </Pagination.Item>
                })
            }                    
        </Pagination>;
    }

    buildSearchResultCard(searchData, index) {
        return <div className="search-card"  onClick={() => {this.handleModalShow(searchData)}} key={index}>
            <p>({(this.state.activePagination - 1) * SEARCH_RESULT_PAGE_COUNT + index + 1})  ERROR TYPE: {searchData.ErrorType}</p>
            <p>DATE: {new Date(searchData.Timestamp).toUTCString()}</p>
            {!!searchData.IPAddress ? <p>IP: {searchData.IPAddress}</p> : <p></p>}
            {!!searchData.ErrorReason ? <p>Error Reason: <br></br>{searchData.ErrorReason}</p> : <p></p>}
            {!!searchData.Products ? <div className="search-card-products">Products: {searchData.Products.split(';').map((product, i) => <p key={i}>{product}</p>)}</div> : null}
            {!!searchData.CartUuid ? <p>Cart UUID: <br></br>{searchData.CartUuid}</p> : <p>Order ID: <br></br>{searchData.OrderID}</p>}
            {!!searchData.Url ? <p className="search-card-url">URL: <strong>{searchData.Url.split('/').at(-1)}</strong></p> : <p></p>}
        </div>;

    }

    searchResults() {
        if (this.props.orderReporting?.isLoadingSearchData) {
            return null;
        }

        if (this.props.orderReporting?.searchResults?.length) {
            return <div>
                {this.buildPagination()}
                {
                    this.props.orderReporting?.searchResults.map((chunk, index) => {
                        return index + 1 === this.state.activePagination ? <div className="search-card-container" key={index}>
                            {
                                chunk.map((data, chunkIndex) => {
                                    return this.buildSearchResultCard(data, chunkIndex);
                                })
                            }
                        </div> : null;
                    })
                }
                {this.buildPagination()}
            </div>
        }
        if (this.state.initiatedSearch) {
            return <p>NO RESULTS</p>
        }
        return null;
    }

    searchData(event) {
        const form = event.currentTarget;

        if (!form.checkValidity()) { 
            event.preventDefault();
            const invalidFields = [];
            for (const element of form.elements) {
                if (!element.validity.valid) {
                    invalidFields.push(
                        {
                            name: element.name,
                            validity: element.validity,
                            value: element.value,
                            pattern: element.pattern,
                            validationMessage: element.validationMessage
                        });
                    }
                }
                console.log('Invalid fields:', invalidFields);
            }


        event.preventDefault();
        event.stopPropagation();

        this.setState({
            validated: true
        });

        if (form.checkValidity() === false) {
            return;
        }

        if (!this.state.fromDate && !this.state.toDate) {
            this.setState({
                errorMessage: 'You must provide one of the query dates!'
            });
            return;
        }

        this.props.fetchOrderReportingDBDataAsync(
            new SearchModel(
                this.state.fromDate,
                this.state.toDate,
                this.state.errorReason,
                this.state.errorType,
                this.state.orderID,
                this.state.targetProducts,
                this.state.ip
            )
        );
        this.setState({
            validated: false,
            activePagination: 1,
            initiatedSearch: true,
            errorMessage: ''
        })
    }

    render() {
        return <AdminWrapper>
            <Modal show={this.state.showSuccessMessage} onHide={this.handleModalClose}>
                <Modal.Body>JSON data copied to clipboard!</Modal.Body>
            </Modal>
            <div>
                <p>You can search specific failed order/cart data to help understand why/how/when it happened.</p>
            </div>
            <p className="error-message">{this.state.errorMessage}</p>

            <Form noValidate validated={this.state.validated} onSubmit={(event) => this.searchData(event)}>
                <InputGroup className="mb-3">
                    <InputGroup.Text>From:</InputGroup.Text>
                    <Form.Group controlId="fromDate">
                        <Form.Control
                            type="datetime-local"
                            aria-label="Small"
                            size="md"
                            value={this.state.fromDate}
                            onChange={e => {
                                this.setState({
                                    fromDate: e.target.value
                                })
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid date.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <InputGroup.Text>To:</InputGroup.Text>
                    <Form.Group controlId="toDate">
                        <Form.Control
                            type="datetime-local"
                            aria-label="Small"
                            size="md"
                            value={this.state.toDate}
                            onChange={e => {
                                this.setState({
                                    toDate: e.target.value
                                })
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid date.
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text>Error Type and Reason</InputGroup.Text>
                    <Form.Control
                        as="select"
                        aria-label="Small"
                        size="md"
                        value={this.state.errorReason}
                        onChange={e => {
                            this.setState({
                                errorReason: e.target.value
                            })
                        }}
                    >
                        <option value=""></option>
                        {
                            Object.values(failedOrderErrors).map((value, index) => {
                                return (<option value={value.ID} key={index}>{value.ID}</option>)
                            })
                        }
                    </Form.Control>
                    <Form.Control
                        as="select"
                        aria-label="Small"
                        size="md"
                        value={this.state.errorType}
                        onChange={e => {
                            this.setState({
                                errorType: e.target.value
                            })
                        }}
                    >
                        <option value=""></option>
                        {
                            failedOrderErrorReasons.map((value, index) => {
                                return (<option value={value} key={index}>{value}</option>)
                            })
                        }
                    </Form.Control>
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text>IP:</InputGroup.Text>
                    <Form.Group className="flex-grow-1" controlId="ip">
                        <Form.Control
                            aria-label="Small"
                            size="md"
                            value={this.state.ip}
                            pattern={this.IP_PATTERN}
                            onChange={e => {
                                this.setState({
                                    ip: e.target.value
                                })
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid IP address.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <InputGroup.Text>Order ID:</InputGroup.Text>
                    <Form.Group controlId="orderID">
                        <Form.Control
                            aria-label="Small"
                            size="md"
                            value={this.state.orderID}
                            onChange={e => {
                                this.setState({
                                    orderID: e.target.value
                                })
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid order ID.
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup>
                
                <InputGroup className="mb-3">
                    <InputGroup.Text>Products (coma seperated):</InputGroup.Text>
                    <Form.Group className="flex-grow-1" controlId="targetProducts">
                        <Form.Control
                            aria-label="Small"
                            size="md"
                            value={this.state.targetProducts}
                            onChange={e => {
                                this.setState({
                                    targetProducts: e.target.value
                                })
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid product list.
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup>

                <Button
                    variant="success"
                    type="submit"
                    disabled={this.props.orderReporting?.isLoadingSearchData}
                >
                    {
                        !this.props.orderReporting?.isLoadingSearchData
                            ? 'Search Data'
                            : <Spinner animation="border" size="sm" />
                    }
                </Button>
            </Form>
            {this.searchResults()}
        </AdminWrapper>
    }
}

const mapStateToProps = (state) => ({
    orderReporting: state.orderReporting
});
  
const mapDispatchToProps = (dispatch) => {
    return {
        fetchOrderReportingDBDataAsync: (searchModel) => dispatch(fetchOrderReportingDBDataAsync(searchModel))
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(AdminFailedOrderSearch);