import { ipStringToLong, longToIpString } from '../helpers/orderReportingHelper';

class StoreUser {
    constructor (storeID, fromIPFormated, toIPFormated = '', role = 'stores', isActive = false) {
        this.storeID = storeID;
        this.fromIPFormated = typeof fromIPFormated === 'string' ? fromIPFormated : longToIpString(fromIPFormated);
        this.fromIP = ipStringToLong(this.fromIPFormated);
        if (toIPFormated) {
            this.toIPFormated = typeof toIPFormated === 'string' ? toIPFormated : longToIpString(toIPFormated);
            this.toIP = ipStringToLong(this.toIPFormated);
        } else {
            this.toIP = this.fromIP + 1;
            this.toIPFormated = longToIpString(this.toIP);
        }
        this.role = role;
        this.isActive = isActive;
    }

    static buildStoreUsers(storeUsersArray) {
        if (!storeUsersArray || !storeUsersArray.length) {
            return [];
        }

        const users = storeUsersArray.map(storeUserDate => new StoreUser(
            storeUserDate.StoreID,
            storeUserDate.IPStart,
            storeUserDate.IPEnd,
            storeUserDate.Role,
            storeUserDate.IsActive
        ).toObject());

        return users;
    }

    static buildStoreClassInstance(storeData) {
        return new StoreUser(
            storeData.storeID,
            storeData.fromIPFormated,
            storeData.toIPFormated,
            storeData.role,
            storeData.isActive
        )
    }

    changeRole(newRole) {
        this.role = newRole;
    }

    [Symbol.equals](other) {
        return this.storeID === other.storeID && this.fromIP === other.fromIP && this.toIP === other.toIP;
    }

    toString() {
        return JSON.stringify({
            storeID: this.storeID,
            fromIPFormated: this.fromIPFormated,
            fromIP: this.fromIP,
            toIPFormated: this.toIPFormated,
            toIP: this.toIP,
            role: this.role,
            isActive: this.isActive
        });
    }

    toObject() {
        return {
            storeID: this.storeID,
            fromIPFormated: this.fromIPFormated,
            fromIP: this.fromIP,
            toIPFormated: this.toIPFormated,
            toIP: this.toIP,
            role: this.role,
            isActive: this.isActive
        };
    }
}

export default StoreUser;
