import React from 'react';
import { connect } from 'react-redux';
import AdminWrapper from './AdminControlPanel';
import "@cyntler/react-doc-viewer/dist/index.css";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css"
import './OrderErrorReporting.css'; 
import { failedOrderErrors } from '../../helpers/orderReportingHelper';

import {
    fetchOrderReportingFilesAsync
} from '../../stateManagment/slices/orderReportingSlice';


class OrderErrorReporting extends React.Component {
    legend = failedOrderErrors

    componentDidMount() {
        const { fetchOrderReportingFilesAsync } = this.props;

        fetchOrderReportingFilesAsync();
    }

    mapPropsToPdfLibraryData() {
        return this.props.orderReporting?.files && this.props.orderReporting?.files.length
         ? this.props.orderReporting.files.map(data => {
            return {
                uri: data.signedUrl,
                fileType: 'pdf'
            }
         })
         : [];
    }

    render() {
        return <AdminWrapper>
            <div>
                {
                    this.props.orderReporting?.files && this.props.orderReporting?.files.length
                        ? <DocViewer
                            prefetchMethod="GET"
                            documents={this.mapPropsToPdfLibraryData()}
                            pluginRenderers={DocViewerRenderers}
                        />
                        : null
                }
            </div>
            <div>
                <h3>Legend</h3>
                <div className='legend'>
                    {Object.keys(this.legend).map((legendElementKey, index) => {
                        let legendElement = this.legend[legendElementKey];

                        return <div key={index} className="legendElement">
                            <span key={index} className="legendErrorName" style={{backgroundColor: legendElement.color}}>{legendElementKey}</span>
                            <div className="colorBox"></div><span>{legendElement.description}</span>
                        </div>
                    })}
                </div>
            </div>
        </AdminWrapper>
    }
}

const mapStateToProps = (state) => ({
    orderReporting: state.orderReporting
});
  
const mapDispatchToProps = (dispatch) => {
    return {
        fetchOrderReportingFilesAsync: () => dispatch(fetchOrderReportingFilesAsync())
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(OrderErrorReporting);